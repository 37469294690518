<template>
  <div class="container-fluid main-color">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="page-title">Add Assessment Criteria Form</h4>
          <div class="col-2">
            <v-select
              class=""
              name="formType"
              :clearable="false"
              id="formType"
              v-model="selectedFormType"
              :options="formTypes"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <place-holder v-if="isLoading"></place-holder>

            <form v-else-if="!isLoading && selectedFormType == 'Single'">
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="name">
                    Name
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <input
                    name="name"
                    id="name"
                    type="text"
                    class="form-control"
                    :class="{
                      'p-invalid': this.v$.formData.name.$error,
                    }"
                    v-model="formData.name"
                  />

                  <v-errors
                    :vuelidateErrors="{
                      errors: this.v$.formData.name.$errors,
                      value: 'Name',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="program">
                    Program Name
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <div class="d-flex">
                    <div class="d-flex align-items-center me-3">
                      <input
                        type="radio"
                        name="choose"
                        :checked="allCheck"
                        @change="
                          allCheck = 1;
                          formData.type = '';
                          formData.level = '';
                          formData.assessment_criteria_percentage_id = '';
                          formData.program = [];
                        "
                      />
                      <label>All</label>
                    </div>
                    <div class="d-flex align-items-center">
                      <input
                        type="radio"
                        name="choose"
                        :checked="!allCheck"
                        @change="
                          allCheck = 0;
                          formData.type = '';
                          formData.level = '';
                          formData.assessment_criteria_percentage_id = '';
                          formData.program = [];
                        "
                      />
                      <label>Select</label>
                    </div>
                  </div>

                  <v-select
                    v-show="!allCheck"
                    name="program"
                    id="program"
                    :options="moduleList"
                    @option:selected="
                      formData.type = '';
                      formData.level = '';
                      formData.assessment_criteria_percentage_id = '';
                    "
                    @option:deselected="
                      formData.type = '';
                      formData.level = '';
                      formData.assessment_criteria_percentage_id = '';
                    "
                    label="name"
                    :loading="!moduleList.length"
                    :class="{
                      'p-invalid': this.v$.formData.program.$error,
                    }"
                    v-model="formData.program"
                    :selectable="(data) => selectedProgram(data)"
                    multiple
                  />

                  <v-errors
                    v-show="!allCheck"
                    :vuelidateErrors="{
                      errors: this.v$.formData.program.$errors,
                      value: 'Program',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="assessment_form">
                    Assessment Form
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <v-select
                    :clearable="false"
                    name="assessment_form"
                    id="assessment_form"
                    :options="assessmentForms"
                    label="name"
                    class="border-danger"
                    v-model="formData.assessment_form"
                    :selectable="
                      (val) => {
                        return val.id !== formData.assessment_form?.id;
                      }
                    "
                  />

                  </div>
              </div>

              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="type">
                    Type
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <v-select
                    :clearable="false"
                    name="type"
                    id="type"
                    :options="getType"
                    label="name"
                    class="border-danger"
                    :class="{
                      'p-invalid': this.v$.formData.type.$error,
                    }"
                    v-model="formData.type"
                    @option:selected="
                      formData.assessment_criteria_percentage_id = '';
                      formData.level = '';
                    "
                    @option:deselected="
                      formData.assessment_criteria_percentage_id = '';
                      formData.level = '';
                    "
                    :selectable="
                      (val) => {
                        return val.id !== formData.type?.id;
                      }
                    "
                  />

                  <v-errors
                    :vuelidateErrors="{
                      errors: this.v$.formData.type.$errors,
                      value: 'Type',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3" v-if="checkLevel()">
                <div class="col-lg-3">
                  <label for="remark">
                    Level
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <v-select
                    :clearable="false"
                    name="level"
                    id="level"
                    :options="getLevel"
                    label="name"
                    :class="{
                      'p-invalid': this.v$.formData.level.$error,
                    }"
                    v-model="formData.level"
                    @option:selected="
                      formData.assessment_criteria_percentage_id = ''
                    "
                    @option:deselected="
                      formData.assessment_criteria_percentage_id = ''
                    "
                    :selectable="
                      (val) => {
                        return val.id !== formData.level?.id;
                      }
                    "
                  />

                  <v-errors
                    :vuelidateErrors="{
                      errors: this.v$.formData.level.$errors,
                      value: 'Level',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="remark">
                    Assessment Criteria Percentage
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <v-select
                    :clearable="false"
                    :options="filterAssessmentCriteria"
                    label="code"
                    :class="{
                      'p-invalid': this.v$.formData
                        .assessment_criteria_percentage_id.$error,
                    }"
                    :reduce="(val) => val.id"
                    v-model="formData.assessment_criteria_percentage_id"
                  >
                    <template #selected-option="{code, percentage}">
                      {{ code && code + ` (${percentage} %)` }}
                    </template>
                    <template v-slot:option="option">
                      {{ option.code }} ({{ option.percentage }}%)
                    </template>
                  </v-select>

                  <v-errors
                    :vuelidateErrors="{
                      errors: this.v$.formData.assessment_criteria_percentage_id
                        .$errors,
                      value: 'Assessment Criteria Percentage',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="remark">
                    Point
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <input
                    name="percentage"
                    id="percentage"
                    type="text"
                    class="form-control"
                    :class="{
                      'p-invalid': this.v$.formData.point.$error,
                    }"
                    v-model="formData.point"
                  />

                  <v-errors
                    :vuelidateErrors="{
                      errors: this.v$.formData.point.$errors,
                      value: 'Point',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mx-auto">
                  <div class="mt-3 mb-3 ms-3 text-center">
                    <router-link :to="{ name: 'assessment-criteria' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>

                    <button
                      :disabled="btnLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="store()"
                    >
                      <span
                        v-if="btnLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>

                      {{ btnLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
              </div>
            </form>

            <MultipleCreate v-if="selectedFormType == 'Multiple'" :assessmentForms="assessmentForms" :assessmentCriteriaPercentages="assessment_criteria_percentage" />

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import { levels } from "../../../const";
import { types } from "../../../const";

import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import validationErrors from "../../../mixins/validationErrors";
import MultipleCreate from "./MultipleCreate.vue";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },

  components: {
    vSelect,
    MultipleCreate
  },

  mixins: [validationErrors],

  data() {
    return {
      assessment_criteria_percentage: [],
      selectedFormType: "Single",
      formTypes: ["Single", "Multiple"],
      formData: {
        name: "",
        type: "",
        level: "",
        point: "",
        assessment_form: "",
        assessment_criteria_percentage_id: "",
        program: [],
      },
      allCheck: 1,
      moduleList: this.$store.getters["odoo/getAllPrograms"],
      getLevel: levels,
      getType: types,
      baseUrl: process.env.VUE_APP_BASE_URL,
      isLoading: true,
      btnLoading: false,
      assessmentForms: [],
      filterAssessment: [],
    };
  },

  validations() {
    return {
      formData: {
        name: { required },
        type: { required },
        level: this.formData.type?.name == "Orientation" ? "" : { required },
        point: { required },
        assessment_criteria_percentage_id: { required },
        program: this.allCheck ? "" : { required },
      },
    };
  },

  methods: {
    async store() {
      if (this.allCheck) {
        this.formData.program = this.moduleList.map((val) => val.id);
      }

      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        return;
      }
      this.btnLoading = true;

      let data = {
        name: this.formData.name,
        type: this.formData.type.name,
        program_ids: this.allCheck
          ? this.formData.program
          : this.formData.program.map((val) => val.id),

        level: this.checkLevel() ? this.formData.level.name : null,
        point: this.formData.point,
        all_check: this.allCheck ? 1 : 0,
        assessment_form: this.formData.assessment_form?.id ?? null,
        assessment_criteria_percentage_id: this.formData
          .assessment_criteria_percentage_id,
      };

      await axios
        .post(`${this.baseUrl}admin/v2/assessment-criteria`, data)

        .then(() => {
          this.btnLoading = false;
          this.$router.push({ name: "assessment-criteria" });
          this.toast.success("Successfully Created Criteria!");
        })
        .catch((error) => {
          this.btnLoading = false;
          error.response.status == 502
            ? this.toast.error(error.response.data.errors.message)
            : this.toast.error("Something Went Wrong!");
        });
    },

    async getAssessmentForms() {
      await axios
        .get(`${this.baseUrl}admin/v2/assessment-form`)
        .then((res) => {
          this.assessmentForms = res.data.data;
          // this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.toast.error("Something Went Wrong!");
        });
    },

    async getAssessmentCriteriaPercentage() {
      await axios
        .get(`${this.baseUrl}admin/v2/assessment-criteria-percentage`)
        .then((res) => {
          this.assessment_criteria_percentage = res.data.data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.toast.error("Something Went Wrong!");
        });
    },
    selectedProgram(option) {
      return !this.formData.program.length
        ? true
        : !this.formData.program.find((p) => p.id == option.id);
    },

    // filterAssessmentPercentage(data) {

    //       if(!this.allCheck) {
    //         let program_id = this.formData.program.map((val)=> val.id)
    //         if(this.formData.type !== "" && this.formData.type.name !== "Orientation"){

    //             if(this.formData.program.length && this.formData.level !== "") {

    //               if(
    //                   this.formData.type.name == data.type &&
    //                   this.formData.level.name == data.level &&
    //                   ( data.programs.some((val) => program_id.includes(val.id)) )
    //               ){

    //                   return true;

    //               }
    //             }
    //         }
    //         if(this.formData.type !== "" && this.formData.type.name == "Orientation"){
    //             if(this.formData.type.name == data.type && this.formData.program.length
    //                && ( data.programs.some((val) => program_id.includes(val.id)) )){
    //                     return true;
    //                 }
    //           }
    //       }
    //       else if(this.allCheck) {

    //           if(this.formData.type !== "" && this.formData.type.name == "Orientation"){

    //               if(this.formData.type.name == data.type){
    //                     return true;
    //                 }

    //           }else if(this.formData.type !== "" && this.formData.level !== "" && this.formData.type.name != "Orientation" ){

    //                 if(this.formData.type.name == data.type &&
    //                 this.formData.level.name == data.level) {

    //                   return true;

    //                 }
    //           }

    //       }

    //   },

    checkLevel() {
      if (this.formData.type !== "") {
        if (this.formData.type?.name !== "Orientation") {
          return true;
        }
      } else {
        return false;
      }
    },
  },

  computed: {
    filterAssessmentCriteria() {
      if (this.allCheck) {
        return this.assessment_criteria_percentage.filter((val) => {
          if (val.all_check) {
            return (
              val.level == this.formData.level?.name &&
              val.type == this.formData.type?.name
            );
          }
        });
      } else {
        return this.assessment_criteria_percentage.filter((val) => {
          let p_ids = val.programs.map((p) => p.id);
          if (this.formData.program.length <= p_ids.length) {
            if (
              this.formData.type?.name == "Orientation" &&
              val.type == "Orientation"
            ) {
              let flag = true;
              Object.values(this.formData.program.map((val) => val.id)).some(
                (p) => {
                  if (!p_ids.includes(p)) {
                    flag = false;
                  }
                }
              );
              return flag;
            } else {
              if (
                val.level == this.formData.level?.name &&
                val.type == this.formData.type?.name
              ) {
                let flag = true;
                Object.values(this.formData.program.map((val) => val.id)).some(
                  (p) => {
                    if (!p_ids.includes(p)) {
                      flag = false;
                    }
                  }
                );
                return flag;
              }
            }
          }
        });
      }
    },
  },

  // watch: {

  //   'formData.program': function(newValue) {

  //      newValue.map((val)=> val.id)
  //     //  console.log( this.filterAssessment = []);
  //       // console.log('click id=>',data)

  //       //     this.assessment_criteria_percentage.filter(
  //       //         (ass) => {
  //       //         if(ass.programs.map((val) => val.id).filter((val) => data.includes(val))) {
  //       //             this.filterAssessment.push(ass)
  //       //         }

  //       //         }
  //       //       //  (ass) => console.log(ass)

  //       //     )
  //           // data = [];
  //           // console.log('Final result=>',this.filterAssessment)

  //   },

  //   'formData.type' : function(newValue) {

  //       console.log(newValue)

  //   },
  //   'formData.level' : function(newValue) {

  //       console.log(newValue)

  //   },

  // },

  async created() {
    await this.getAssessmentForms();
    await this.getAssessmentCriteriaPercentage();
  },
};
</script>
